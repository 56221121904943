import * as React from "react"
import { useRef } from "react"
import { useState, useEffect } from "react"

import Layout from "../../components/layout"
import NoScript from "../../components/noscript"
import Seo from "../../components/seo"

const INDENT_WIDTH = 4;
const NEWLINES_PATTERN = /\n\s*\n/;
function indent(n) {
    return ' '.repeat(INDENT_WIDTH * n);
}
function parseSolrQuery(text) {
    let out = '';
    let obuffer = '';
    let icount = 0;
    let in_term = false;
    let i = 0;
    while (i < text.length) {
        let c = text[i];
        if (!/\s/.test(c)) {
            if (in_term) {
                if (c === ')') {
                    out += obuffer + c;
                    in_term = false;
                } else if (/[0-9A-Za-z]+/.test(c) || c === '_' || c === ':' || c === '~' || c === '*') {
                    obuffer += c;
                } else {
                    icount += 1;
                    out += '(\n' + indent(icount) + obuffer.slice(1);
                    in_term = false;
                    continue;
                }
            } else {
                if (c === '(') {
                    if (out.length > 0 && /d/.test(out[out.length - 1])) {
                        out += '\n' + indent(icount);
                    }
                    obuffer = c;
                    in_term = true;
                } else if (c === '+') {
                    out += '\n' + indent(icount) + c;
                } else if (c === '|') {
                    out += '\n' + indent(icount) + '|\n' + indent(icount);
                } else if (c === ')') {
                    icount -= 1;
                    out += '\n' + indent(icount) + ')';
                    if (!(i + 1 < text.length && (text[i + 1] === '|' || text[i + 1] === '^' || text[i + 1] === ')'))) {
                        out += '\n' + indent(icount);
                    }
                } else {
                    out += c;
                }
            }
        }
        i += 1;
    }
    return out.replace(NEWLINES_PATTERN, '\n');
}



const getParsedQuery = (queryParams) => {
    if (!queryParams) {
        return '';
    }

    const qParam = queryParams.substring(1)
        .split('&')
        .filter(param => param.startsWith('q='))
        .map(param => param.substring(2))[0];
    if (!qParam) {
        return '';
    }

    return  atob(qParam);
};


const SolrPage = () => {
    const [parsedQuery, setParsedQuery] = useState('');

    useEffect(() => {
        const queryParams = window.location.search;
        setParsedQuery(getParsedQuery(queryParams));
    }, []);

    return <Layout>
        <Seo title="Solr Query Reformat" />
        <h1>Solr query formatter</h1>

        <h3>Original query:</h3>
        <input type="text" className="w-full overflow-x-scroll bg-gray-100 p-2 text-sm" value={parsedQuery} onChange={e => setParsedQuery(e.target.value)}/>

        <h3>Formatted query:</h3>
        <pre className="w-full overflow-x-scroll bg-gray-100 p-2 text-sm">
            {parseSolrQuery(parsedQuery)}
        </pre>
    </Layout>;
};

export default SolrPage;

